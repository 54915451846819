import { WORKFLOW_NEW_EMAIL_EDITOR_LAUNCH_VERSION } from 'config';
import { WORKFLOW_INITIAL_VERSION } from 'views/admin/dashboards/workflowEditor/variables';
import semver from 'semver';
import { TemplateBlock } from '@engagespot/template-blocks';
import { uploadFilesToServerAndGetUrl } from 'api/files/post';
import { getTemplateDataFromJson } from 'api/templates/get-template-json';
import {
  GetTemplateContent,
  GetTemplateContents,
} from 'api/templates/get-template/types';
import { updateTemplates } from 'api/templates/update-templates';
import { UseFormReturn } from 'react-hook-form';
import { TemplateTabs } from 'store/templateStore';
import {
  allTemplateDetails,
  inAppTemplateIds,
  TemplateStates,
} from 'templates';
import {
  $currentTemplate,
  $templateConfigCurrentState,
  $templateConfigValues,
} from 'templates/store';
import {
  createUniqueTemplateBlockId,
  DEFAULT_TEMPLATE_STATE,
} from 'templates/utils';
import { asyncReduce, capitalizeFirstLetter } from 'utils/functions';
import { customToast } from 'utils/use-toast';
import { setUpdatedWorkflowJson } from '../workflowEditor/functions';
import { TEMPLATE_EDITOR_TYPES } from './variables/constants';
import { TemplateEditorAvailabeChannels } from './variables/inputs';
import {
  EditWorkflowJsonMutate,
  SaveEmailWorkflow,
  SaveEmailWorkflowResponse,
} from 'api/workflows/types';
import { UseMutationResult } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { TEditorConfiguration } from 'email-editor/documents/editor/core';
import {
  renderToStaticMarkup,
  TReaderDocument,
} from '@usewaypoint/email-builder';
import {
  resetDocument,
  setFileToDelete,
  setFileToUpload,
} from 'email-editor/documents/editor/EditorContext';
import EMPTY_EMAIL_MESSAGE from 'email-editor/getConfiguration/sample/empty-email-message';
import { PatchUpdateLayoutJson, PostSaveEmailLayout } from 'api/layouts/types';
import { useLayoutStore } from 'store/layoutStore';
import { R } from 'utils/remeda-utils';
import { componentBlockConfig, EngageSpotNotification } from 'templates/types';
import { Channels } from 'api/channels/get-channels/types';

const registerImageParseCallback = (r: React.MutableRefObject<any>) => {
  if (r?.current) {
    r.current?.editor.registerCallback(
      'image',
      function (file: any, done: ({ url }: { url: string }) => void) {
        parseImageUpload(file, done);
      },
    );
  }
};

export const onEmailTemplateReady = async ({
  emailEditorRef,
  content,
  isOldVersion,
}: {
  emailEditorRef: React.MutableRefObject<any>;
  content: GetTemplateContents['contents'][0];
  isOldVersion: boolean;
}) => {
  await initializeEditorTemplateValues(
    emailEditorRef,
    'content',
    content,
    isOldVersion,
  );

  [emailEditorRef].forEach(r => {
    if (isOldVersion) return;
    registerImageParseCallback(r);
  });
};

export const initializeEditorTemplateValues = async (
  editorRef: React.MutableRefObject<any>,
  type: 'content' | 'batchingContent',
  content: GetTemplateContents['contents'][0],
  isOldVersion: boolean,
) => {
  const data = (content?.[type] as GetTemplateContent)?.bodyJsonTemplate;

  if (data) {
    try {
      const response = await getTemplateDataFromJson(data);

      if (isOldVersion) {
        editorRef.current?.editor.loadDesign(response);
      } else {
        useLayoutStore.getState().setSavedLayout(response);
        resetDocument(response);
      }
    } catch (e) {
      console.log(e);
    }
  } else {
    if (!isOldVersion) {
      resetDocument(EMPTY_EMAIL_MESSAGE);
    }
  }
};

export const parseImageUpload = (
  file: any,
  done: ({ url }: { url: string }) => void,
) => {
  if (file.attachments?.[0]) {
    const imageFormData = new FormData();
    imageFormData.append('image', file.attachments[0]);
    uploadFilesToServerAndGetUrl(imageFormData)
      .then(res => {
        done({ url: res.data.url });
      })
      .catch(e => {
        console.log(e);
      });
  }
};

export const extractDataFromEditor = async (
  editorRef: React.MutableRefObject<any>,
) =>
  new Promise(resolve => {
    if (editorRef?.current) {
      editorRef.current?.editor.exportHtml(async (data: any) => {
        resolve(data);
      });
    } else {
      resolve(null);
    }
  });

export const handleEmailLayoutSave = async ({
  postSaveEmailLayout,
  patchUpdateLayoutJson,
  layoutName,
  bodyJsonTemplate,
  bodyHtmlTemplate,
  id,
}: {
  postSaveEmailLayout: UseMutationResult<
    AxiosResponse<any, any>,
    unknown,
    PostSaveEmailLayout,
    unknown
  >;
  patchUpdateLayoutJson: UseMutationResult<
    AxiosResponse<any, any>,
    unknown,
    PatchUpdateLayoutJson,
    unknown
  >;
  layoutName?: string;
  bodyJsonTemplate: string;
  bodyHtmlTemplate: string;
  id: string;
}) => {
  const response = await postSaveEmailLayout.mutateAsync({
    layoutId: Number(id),
    name: layoutName,
    bodyJsonTemplate,
    bodyHtmlTemplate,
  });

  await patchUpdateLayoutJson.mutateAsync({
    layoutId: Number(id),
    jsonSpec: response.data,
  });
};

export const onEmailTemplateSave = async ({
  emailEditorRef,
  batchingEmailEditorRef,
  setLoading,
  channelType,
  values,
  id,
  toast,
  tab,
  setIsEmailDirty,
  templateIdentifier,
  editorType,
  editWorkflowJson,
  saveEmailWorkflow,
  document,
  isOldVersion,
  postSaveEmailLayout,
  patchUpdateLayoutJson,
  layoutName,
}: {
  emailEditorRef: React.MutableRefObject<any>;
  batchingEmailEditorRef: React.MutableRefObject<any>;
  setIsEmailDirty: React.Dispatch<React.SetStateAction<boolean>>;
  setLoading: (loading: boolean) => void;
  channelType: 'email';
  values: any;
  id: string;
  toast: customToast;
  tab: TemplateTabs;
  editorType: string;
  templateIdentifier: string;
  document: TEditorConfiguration;
  isOldVersion: boolean;
  editWorkflowJson: EditWorkflowJsonMutate;
  saveEmailWorkflow: UseMutationResult<
    AxiosResponse<SaveEmailWorkflowResponse, any>,
    unknown,
    SaveEmailWorkflow,
    unknown
  >;
  postSaveEmailLayout: UseMutationResult<
    AxiosResponse<any, any>,
    unknown,
    PostSaveEmailLayout,
    unknown
  >;
  patchUpdateLayoutJson: UseMutationResult<
    AxiosResponse<any, any>,
    unknown,
    PatchUpdateLayoutJson,
    unknown
  >;
  layoutName?: string;
}) => {
  try {
    const editorTypeTemplate = editorType === TEMPLATE_EDITOR_TYPES.TEMPLATE;
    const editorTypeWorkflow = editorType === TEMPLATE_EDITOR_TYPES.WORKFLOW;
    const editorTypeLayout = editorType === TEMPLATE_EDITOR_TYPES.LAYOUT;
    const layoutIdentifier = useLayoutStore.getState().layoutIdentifier;

    // Since workflows, no need to support template batching
    const isBatchingEnabled = false;

    setLoading(true);

    // default
    const emailEditorData = isOldVersion
      ? await extractDataFromEditor(emailEditorRef)
      : {};
    // for old versions we use unlayer editor, https://github.com/Unlayer/react-email-editor
    // for new versions we use usewaypoint editor, https://github.com/usewaypoint/email-builder-js
    const bodyJsonTemplate = isOldVersion
      ? JSON.stringify((emailEditorData as any)?.design)
      : JSON.stringify(document, null, '  ');

    const bodyHtmlTemplate = isOldVersion
      ? btoa(encodeURIComponent((emailEditorData as any).html))
      : btoa(
          encodeURIComponent(
            renderToStaticMarkup(document as TReaderDocument, {
              rootBlockId: 'root',
            }),
          ),
        );

    const templateData = {
      content: {
        subject: values.subject ?? '',
        attachmentKey: values.attachmentKey ?? '',
        layout: layoutIdentifier ?? '',
        bodyJsonTemplate,
        bodyHtmlTemplate,
      },
    };

    // directly editing from template editor is @deprecated
    if (editorTypeTemplate) {
      await updateTemplates({
        id,
        channelType,
        data: templateData,
        type: 'put_content',
      });

      await updateTemplates({
        id,
        channelType,
        data: {
          batchingEnabled: isBatchingEnabled,
          batchingWindow: parseInt(values.batchingWindow),
        },
        type: 'patch_content',
      });
    }

    if (editorTypeWorkflow) {
      const response = await saveEmailWorkflow.mutateAsync({
        workflowId: Number(id),
        data: templateData.content,
      });

      await setUpdatedWorkflowJson({
        templateIdentifier,
        template: response.data,
        editWorkflowJson,
        templateId: id,
      });
    }

    if (editorTypeLayout) {
      await handleEmailLayoutSave({
        postSaveEmailLayout,
        patchUpdateLayoutJson,
        layoutName,
        bodyJsonTemplate,
        bodyHtmlTemplate,
        id,
      });
    }

    if (isBatchingEnabled) {
      const batchingEmailEditorData = await extractDataFromEditor(
        batchingEmailEditorRef,
      );

      const batchingTemplateData = {
        content: {
          subject: values.batchingSubject,
          bodyHtmlTemplate: btoa(
            encodeURIComponent((batchingEmailEditorData as any).html),
          ),
          bodyJsonTemplate: JSON.stringify(
            (batchingEmailEditorData as any).design,
          ),
        },
      };

      if (editorTypeTemplate) {
        await updateTemplates({
          id,
          channelType,
          data: batchingTemplateData,
          type: 'put_batching_content',
        });
      }
    }

    setFileToUpload([]);
    setFileToDelete([]);
    toast.success('template saved successfully');
    setIsEmailDirty(false);
    setLoading(false);
  } catch (e) {
    console.log({ e });
    toast.showError(e);
    setIsEmailDirty(false);
    setLoading(false);
  }
};

export const handleFormSetValues = (
  data: any,
  form: UseFormReturn<any, any>,
) => {
  const jsonKeys = ['embeds', 'batchingEmbeds', 'blocks', 'batchingBlocks'];

  Object.keys(data).forEach(key => {
    if (key.includes('icon') || key.includes('Icon')) return;

    if (
      !jsonKeys.includes(key) &&
      typeof data[key] !== 'string' &&
      typeof data[key] !== 'boolean'
    ) {
      return;
    }

    form.setValue(key, data[key]);
  });
};

export const handleGetInAppConfigValues = (finalPromptData: any) => {
  // set the values
  const response = Object.keys(finalPromptData).reduce((acc, key) => {
    if (key === 'blocks') {
      const blocksData = finalPromptData['blocks'].reduce(
        (acc: any, item: any, index: number) => {
          return {
            ...acc,
            [`state_1_button${index + 1}_label`]: item.label,
          };
        },
        {},
      );

      return {
        ...acc,
        ...blocksData,
      };
    }

    if (key === 'states') {
      const statesData = Object.keys(finalPromptData['states']).reduce(
        (outerAcc, outerKey, outerIndex) => {
          const mappedData = Object.keys(
            finalPromptData['states'][outerKey],
          ).reduce((innerAcc: any, innerKey: any) => {
            return {
              ...innerAcc,
              [`state_1_button${outerIndex + 1}_active`]: true,
              [`state_${outerIndex + 2}_${innerKey}`]:
                finalPromptData['states'][outerKey][innerKey],
            };
          }, {});

          return { ...outerAcc, ...mappedData };
        },
        {},
      );

      return {
        ...acc,
        ...statesData,
      };
    }

    return { ...acc, [`state_1_${key}`]: finalPromptData[key] };
  }, {});

  return response;
};

export const handleSelectInAppLayout = (blocks: TemplateBlock[]) => {
  let templateId: inAppTemplateIds = 'default';

  if (blocks.length === 1 && blocks?.[0]?.type) {
    if (blocks[0].type === 'button') {
      templateId = 'one_button';
    }

    if (blocks[0].type === 'image') {
      templateId = 'banner_image';
    }

    //@ts-ignore
    if (blocks[0].type === 'textarea' || blocks[0].type === 'input') {
      templateId = 'one_input';
    }
  }

  if (blocks.length === 2) {
    if (blocks?.[0]?.type === 'button' && blocks?.[0]?.type === 'button') {
      templateId = 'two_button';
    }
  }

  const templateDefaultValues =
    allTemplateDetails?.[templateId]?.['defaultValues'];

  $templateConfigValues.set(templateDefaultValues);
  $templateConfigCurrentState.set(DEFAULT_TEMPLATE_STATE);
  $currentTemplate.set(templateId);
};

export const getAiModalData = ({
  data,
  channel,
  tab,
}: {
  data: any;
  channel: TemplateEditorAvailabeChannels;
  tab: TemplateTabs;
}) => {
  const { content } = data;
  const batchingTab = tab === 'batched_template';

  const promptData = {
    ...data,
    ...(content && typeof content === 'object' && content),
    ...(channel === 'discord' && {
      embeds: JSON.stringify(
        (content as GetTemplateContent)?.embeds,
        undefined,
        4,
      ),
    }),
    ...(channel === 'slack' && {
      blocks: JSON.stringify(
        (content as GetTemplateContent)?.blocks,
        undefined,
        4,
      ),
    }),
  };

  const batchingPromptData =
    batchingTab &&
    Object.fromEntries(
      Object.entries(promptData)?.map(([key, value]) => {
        const capitalisedKey = capitalizeFirstLetter(key);
        return [`batching${capitalisedKey}`, value];
      }),
    );

  const finalPromptData = batchingTab ? batchingPromptData : promptData;

  return finalPromptData;
};

/**
 * if using template editor, then it's old version
 * if using layout editor, then it's new version
 * else if workflow, then we check based on workflow version
 */
export const checkIfOldEmailEditorVersion = ({
  actingAsTemplateEditor,
  actingAsLayoutEditor,
  workflowVersion,
}: {
  actingAsTemplateEditor: boolean;
  actingAsLayoutEditor: boolean;
  workflowVersion: string;
}) => {
  const isOldVersion = actingAsTemplateEditor
    ? true
    : actingAsLayoutEditor
      ? false
      : semver.lt(
            semver.coerce(workflowVersion || WORKFLOW_INITIAL_VERSION).version,
            semver.coerce(WORKFLOW_NEW_EMAIL_EDITOR_LAUNCH_VERSION).version,
          )
        ? true
        : false;

  return isOldVersion;
};

export const getDocumentWithLayout = ({
  document,
  documentLayout,
}: {
  document: TEditorConfiguration;
  documentLayout: TEditorConfiguration;
}) => {
  const blockToReplace: Array<string> = R.pipe(
    documentLayout,
    R.pickBy(
      (value: any, key: string) =>
        value?.data?.props?.text?.includes('{{body}}') ||
        value?.data?.props?.contents?.includes('{{body}}'),
    ),
    R.keys(),
  );

  //Find the parent block (such as Container) of blockToReplace
  const parentBlockIds = R.pipe(
    documentLayout,
    R.pickBy((value: any, key: string) =>
      (value?.data as any)?.props?.childrenIds?.includes(
        blockToReplace[0] as string,
      ),
    ),
    R.keys(),
  );

  const documentWithoutRoot = R.pipe(
    document,
    R.omitBy((value, key) => key === 'root'),
  );
  const clonedLayout = R.clone(documentLayout);

  //If there is no parent, then it means that it is under Root
  if (parentBlockIds.length === 0) {
    const indexOfBlockToReplace = (
      clonedLayout.root.data as any
    ).childrenIds.indexOf(blockToReplace[0]);
    (clonedLayout.root.data as any).childrenIds.splice(
      indexOfBlockToReplace as number,
      1,
      ...(document.root.data as any).childrenIds,
    );
  } else {
    //The blockToReplace is the child of a container.
    //We should add all the children from our template to the layout, and then update the children of this parent.

    const indexOfBlockToReplace: number = (
      clonedLayout[parentBlockIds[0]].data as any
    ).props.childrenIds.indexOf(blockToReplace[0] as string);

    (clonedLayout[parentBlockIds[0]].data as any).props.childrenIds.splice(
      indexOfBlockToReplace,
      1,
      ...(document.root.data as any).childrenIds,
    );
  }

  const mergedChildren = R.mergeDeep(documentWithoutRoot, clonedLayout);

  return mergedChildren;
};

const uploadFile = async ({
  file,
  toast,
  channel,
  setLoading,
}: {
  file: File;
  toast: customToast;
  channel: Channels;
  setLoading: (loading: boolean) => void;
}) => {
  try {
    const imageFormData = new FormData();
    imageFormData.append('image', file as Blob);

    if (channel === 'whatsapp') {
      imageFormData.append('contentType', file.type);
    }

    const uploadResponse = await uploadFilesToServerAndGetUrl(imageFormData);
    return uploadResponse?.data?.url;
  } catch (err) {
    toast.showError(err);
    setLoading(false);
  }
};

const getMobileWebInAppCommonData = (data: any) => {
  return {
    templateData: {
      content: {
        title: data.title,
        message: data.message,
        icon: data.icon,
        url: data.url,
      },
    },

    batchedTemplateData: {
      content: {
        title: data.batchingTitle,
        content: data.batchingContent,
        icon: data.batchingIcon,
      },
    },
  };
};

export async function isFileThenConvertToUrl({
  data,
  toast,
  channel,
  setLoading,
}: {
  data: any;
  toast: customToast;
  channel: Channels;
  setLoading: (loading: boolean) => void;
}) {
  const fileInputNames = [
    'icon',
    'url',
    'batchingIcon',
    'batchingUrl',
    `${DEFAULT_TEMPLATE_STATE}_banner_image`,
    'src',
  ];

  const listOfFiles = Object.keys(data).filter((value: string) => {
    return fileInputNames.some(v => value.includes(v));
  });

  await Promise.all(
    listOfFiles.map(async key => {
      if (Boolean(data[key]) && typeof data[key] !== 'string') {
        const file = data[key][0];
        data[key] = await uploadFile({ file, toast, channel, setLoading });
      }
    }),
  );
}

// TODO: refactor getTemplateData,
// instead of manually assigning key value pairs,
// get this data from the inputConfig
export const getTemplateData = async ({
  values,
  channel,
  tab,
  filteredTemplateConfigAllStates,
  templateIdentifier,
  templateConfigValues,
  currentTemplate,
  toast,
  setLoading,
}: {
  values: any;
  channel: Channels;
  tab: TemplateTabs;
  filteredTemplateConfigAllStates: TemplateStates[];
  templateIdentifier: string;
  templateConfigValues: any;
  currentTemplate: inAppTemplateIds;
  toast: customToast;
  setLoading: (loading: boolean) => void;
}) => {
  const data = { ...values };

  // TODO: currently mutating, refactor this
  await isFileThenConvertToUrl({ data, toast, channel, setLoading });

  if (channel === 'sms') {
    return {
      templateData: {
        content: {
          message: data.content,
        },
      },

      batchedTemplateData: {
        content: { message: data.batchingContent },
      },
    };
  }

  if (channel === 'discord') {
    return {
      templateData: {
        content: {
          message: data.message,
          username: data.username,
          avatar_url: data.avatar_url,
          ...(data.embeds && {
            embeds: JSON.parse(data.embeds),
          }),
        },
      },

      batchedTemplateData: {
        content: {
          message: data.batchingMessage,
          username: data.batchingUsername,
          avatar_url: data.batchingAvatarUrl,
          ...(data.batchingEmbeds && {
            embeds: JSON.parse(data.batchingEmbeds),
          }),
        },
      },
    };
  }

  if (channel === 'slack') {
    return {
      templateData: {
        content: {
          message: data.message,
          ...(data.blocks && {
            blocks: JSON.parse(data.blocks),
          }),
        },
      },

      batchedTemplateData: {
        content: {
          message: data.batchingMessage,
          ...(data.batchingBlocks && {
            blocks: JSON.parse(data.batchingBlocks),
          }),
        },
      },
    };
  }

  if (channel === 'webPush' || channel === 'mobilePush') {
    return getMobileWebInAppCommonData(data);
  }

  if (channel === 'whatsapp') {
    return {
      templateData: {
        content: {
          ...(data.whatsapp_provider === 'twilio'
            ? {
                contentSid: data.contentSid,
                messagingServiceSid: data.messagingServiceSid,
                messageType: 'text',
              }
            : data.whatsapp_provider === 'whatsapp_cloud_api'
              ? {
                  messageType: 'text',
                  message: 'dummy',
                  template: {
                    name: data.templateName,
                    language: {
                      code: data.languageCode,
                    },
                  },
                }
              : {
                  messageType: data.messageType,
                  message: data.message,
                  ...(values.messageType !== 'text' && {
                    [values.messageType + 'Url']:
                      typeof values.url === 'string' ? values.url : data.url,
                  }),
                }),
        },
      },

      batchedTemplateData: {
        content: {
          messageType: data.batchingMessageType,
          message: data.batchingMessage,
          ...(values.batchingMessageType !== 'text' && {
            [values.batchingMessageType + 'Url']:
              typeof values.batchingUrl === 'string'
                ? values.batchingUrl
                : data.batchingUrl,
          }),
        },
      },
    };
  }

  if (channel === 'inApp') {
    const templateBlocksConfig =
      allTemplateDetails?.[currentTemplate]?.['blockConfig']?.(
        templateConfigValues,
      );

    const templateBlocksConfigWithImageUrls = await asyncReduce(
      Object.keys(templateBlocksConfig),
      async (acc, key) => {
        const newArray = await asyncReduce(
          templateBlocksConfig[key],
          async (acc, item) => {
            await isFileThenConvertToUrl({
              data: item,
              toast,
              channel,
              setLoading,
            });
            acc.push(item);
            return acc;
          },
          [],
        );
        acc[key] = newArray;
        return acc;
      },
      {} as componentBlockConfig,
    );

    const templateBlocksConfigWithUniqueIds = Object.keys(
      templateBlocksConfigWithImageUrls,
    ).reduce((acc, curr) => {
      const newCurrentValues = templateBlocksConfig[curr].map((item, index) => {
        /**
         * unique id of format - template${id}_batching${boolean}_${templateState}_item${index}
         * eg: t84_b0_default_i0
         */
        const templateState = curr;
        const batchingState = tab === 'template' ? 0 : 1;
        const uniqueIdForItem = createUniqueTemplateBlockId({
          templateIdentifier,
          batchingState,
          templateState,
          index,
        });

        return {
          ...item,
          id: uniqueIdForItem,
        };
      });

      return {
        ...acc,
        [curr]: newCurrentValues,
      };
    }, {} as componentBlockConfig);

    const templateConfig: { [x: string]: EngageSpotNotification } =
      filteredTemplateConfigAllStates?.reduce((acc, curr) => {
        const currentKey = curr.key;

        const currentStateConfig = {
          blocks: templateBlocksConfigWithUniqueIds?.[currentKey],
          title: data?.[`${currentKey}_title`],
          message: data?.[`${currentKey}_message`],
          icon: data?.[`${currentKey}_icon`],
          url: data?.[`${currentKey}_url`],
        };

        if (currentKey === DEFAULT_TEMPLATE_STATE) {
          return {
            ...acc,
            ...currentStateConfig,
          };
        }

        return {
          ...acc,
          states: {
            ...(acc as any)['states'],
            [currentKey]: currentStateConfig,
          },
        };
      }, {});

    return {
      templateData: {
        content: templateConfig,
      },

      batchedTemplateData:
        getMobileWebInAppCommonData(data)?.['batchedTemplateData'],

      rawData: data,
    };
  }
};

export function isValidHTML(html: string) {
  // const doc = new DOMParser().parseFromString(htmlString, 'text/html');
  // const generatedHTML = doc.body.innerHTML.trim();
  // return generatedHTML === htmlString.trim();

  const tagPattern = /<\/?([a-zA-Z0-9]+)[^>]*>/g;
  const selfClosingTags = new Set([
    'area',
    'base',
    'br',
    'col',
    'embed',
    'hr',
    'img',
    'input',
    'link',
    'meta',
    'param',
    'source',
    'track',
    'wbr',
  ]);
  let stack = [];
  let match;

  while ((match = tagPattern.exec(html)) !== null) {
    let tag = match[1];

    if (html[match.index + 1] === '/') {
      // Closing tag: check if it matches the last opened tag
      if (stack.length === 0 || stack.pop() !== tag) {
        return false;
      }
    } else if (!selfClosingTags.has(tag)) {
      // Opening tag: push it to the stack
      stack.push(tag);
    }
  }

  // If the stack is empty, all tags were properly closed
  return stack.length === 0;
}
