import { useDisclosure } from '@chakra-ui/hooks';
import usePatchTemplateContent from 'api/templates/patch-content';
import { chipTextColors } from 'components/chip';
import Dropdown from 'components/dropdown';
import React from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { useNavigate, useParams } from 'react-router-dom';
import { channelsLogo } from 'variables/channelsLogo';
import {
  WORKFLOWDATA_EVENT_TRIGGERED_ID,
  ChannelWorkflow,
} from '../variables/data';
import TriggerModal from './TriggerModal';
import useCustomToast from 'utils/use-toast';
import { RxDragHandleDots2 } from 'react-icons/rx';
import { SelectOptions } from 'components/fields/SelectField/types';
import { TEMPLATE_EDITOR_TYPES } from '../../templateEditor/variables/constants';
import { getHashidsInstance } from 'utils/use-hash-id';

const Container = ({ children }: { children: JSX.Element | JSX.Element[] }) => {
  return <div className="m-2 rounded-sm text-white">{children}</div>;
};

const ChannelList = ({
  children,
  innerRef,
}: {
  children: JSX.Element | JSX.Element[] | React.ReactNode | React.ReactNode[];
  innerRef: (element: HTMLElement) => void;
}) => {
  return (
    <div ref={innerRef} className="flex flex-col items-center p-2">
      {children}
    </div>
  );
};

const ChannelMenu = (props: {
  transparent?: boolean;
  vertical?: boolean;
  selected: ChannelWorkflow['channels']['x'];
  channelOptions: SelectOptions[];
}) => {
  const { transparent, vertical, selected, channelOptions } = props;
  const [openWrapper, setOpenWrapper] = React.useState(false);
  const navigate = useNavigate();
  const params = useParams();
  const hashIds = getHashidsInstance();
  const templateId = hashIds.decode(params.templateId);
  const triggerModalActions = useDisclosure();
  const patchContent = usePatchTemplateContent();
  const toast = useCustomToast();

  const handleMenuClick = (actionType: 'view' | 'trigger' | 'disable') => {
    if (actionType === 'view') {
      navigate(
        `/admin/template-editor/${params.templateId}/${selected?.channelIdentifier}`,
      );
    }

    if (actionType === 'trigger') {
      triggerModalActions.onOpen();
    }

    if (actionType === 'disable') {
      const data = {
        channelType: selected?.channelIdentifier,
        templateId: String(templateId),
        data: {
          isDisabled: !selected?.isDisabled,
        },
      };
      setOpenWrapper(false);

      patchContent.mutate(data, {
        onError: err => {
          toast.showError(err);
        },
      });
    }
  };

  return (
    <>
      <TriggerModal
        isOpen={triggerModalActions.isOpen}
        onClose={triggerModalActions.onClose}
        selected={selected}
        channelOptions={channelOptions}
      />

      <Dropdown
        openWrapper={openWrapper}
        setOpenWrapper={setOpenWrapper}
        button={
          <button
            className={`flex items-center rounded-full text-xl hover:cursor-pointer ${
              transparent
                ? 'bg-none text-white hover:bg-none active:bg-none'
                : vertical
                  ? 'rotate-0'
                  : 'rotate-90'
            } linear h-8 w-8 justify-center rounded-lg font-bold transition duration-200`}
          >
            <p className="text-[20px] hover:cursor-pointer">
              <BsThreeDotsVertical />
            </p>
          </button>
        }
        animation={'origin-[10%_-15%] transition-all duration-300 ease-in-out'}
        classNames={`${transparent ? 'top-8' : 'top-9'}  w-max`}
        children={
          <div className="z-50 w-max rounded-xl border border-[#434343] bg-white px-3 py-2 text-sm shadow-xl shadow-shadow-500 dark:!bg-gray-850 dark:shadow-none">
            {/* <button
              onClick={() => handleMenuClick("view")}
              className="flex cursor-pointer items-center gap-2 rounded-xl p-2 px-4 font-medium text-white  hover:bg-black"
            >
              View
            </button> */}
            <button
              onClick={() => handleMenuClick('trigger')}
              className="flex cursor-pointer items-center gap-2 rounded-xl p-2 px-4 font-medium text-white  hover:bg-black"
            >
              Trigger Configuration
            </button>
            <button
              onClick={() => handleMenuClick('disable')}
              className="flex cursor-pointer items-center gap-2 rounded-xl p-2 px-4 font-medium text-white  hover:bg-black"
            >
              {selected?.isDisabled ? 'Enable' : 'Disable'}
            </button>
          </div>
        }
      />
    </>
  );
};

const Channel = ({
  channel,
  index,
  templateIdentifier,
  channels,
}: {
  channel: ChannelWorkflow['channels']['x'];
  index: number;
  templateIdentifier: string;
  channels: ChannelWorkflow['channels']['inApp'][];
}) => {
  const navigate = useNavigate();
  const params = useParams();
  const isEventTriggered =
    String(channel.id) === WORKFLOWDATA_EVENT_TRIGGERED_ID;

  const handleView = () => {
    navigate(
      `/admin/template-editor/${params.templateId}/${channel?.channelIdentifier}/${templateIdentifier}/${TEMPLATE_EDITOR_TYPES.TEMPLATE}`,
    );
  };

  // new terirotry
  function filterUpTo(stopCondition: string) {
    let reached = false;

    return function (element: ChannelWorkflow['channels']['x']) {
      if (reached) {
        return false;
      }

      if (element.channelIdentifier === stopCondition) {
        reached = true;
        return false;
      }

      return true;
    };
  }

  const channelsAboveCurrent = channels
    .filter(filterUpTo(channel.channelIdentifier))
    .filter(channel => channel.channelIdentifier !== 'event_trigger')
    .map(item => ({
      label: item.content,
      value: item.channelIdentifier,
    }));

  return (
    <Draggable
      draggableId={String(channel?.id)}
      isDragDisabled={isEventTriggered}
      index={index}
    >
      {(provided, snapshot) => (
        <div
          onClick={handleView}
          className={`relative mb-3 flex w-[345px] select-none gap-2 rounded-lg p-3 ${
            isEventTriggered ? 'pointer-events-none pl-16' : ''
          } ${snapshot.isDragging ? 'bg-gray-850' : 'bg-gray-850'}`}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          {/* absolute position button */}
          {!isEventTriggered && (
            <div
              onClick={e => e.stopPropagation()}
              className="absolute right-1 top-1"
            >
              <ChannelMenu
                channelOptions={channelsAboveCurrent}
                selected={channel}
              />
            </div>
          )}

          {/* Drag Handle Icon */}
          {!isEventTriggered && (
            <RxDragHandleDots2
              size={'2.2rem'}
              className="relative top-1 text-white/50"
            />
          )}

          <div className="flex items-center justify-between gap-4">
            <img
              width={44}
              src={channelsLogo?.[channel?.channelIdentifier]}
              alt=""
            />

            <div>
              <p className="font-semibold text-white">{channel?.content}</p>

              {/* if event trigger ignore texts */}
              {String(channel.id) !== WORKFLOWDATA_EVENT_TRIGGERED_ID && (
                <p
                  className={`${
                    channel?.isDisabled
                      ? chipTextColors['red']
                      : channel?.isConfigured
                        ? channel?.isActive
                          ? chipTextColors['darkGreen']
                          : chipTextColors['orange']
                        : chipTextColors['darkYellow']
                  }`}
                >
                  {channel?.isDisabled
                    ? 'Not sending (disabled)'
                    : channel?.isConfigured
                      ? channel?.isActive
                        ? 'Active'
                        : 'Not sending (channel inactive)'
                      : 'Not sending (channel not enabled)'}
                </p>
              )}
            </div>
          </div>
        </div>
      )}
    </Draggable>
  );
};

export const DroppableColumn = ({
  column,
  channels,
  templateIdentifier,
}: {
  column: ChannelWorkflow['columns']['addedChannels'];
  channels: ChannelWorkflow['channels']['inApp'][];
  templateIdentifier: string;
}) => {
  return (
    <>
      <Container>
        <Droppable droppableId={column.id}>
          {provided => (
            <ChannelList
              innerRef={provided.innerRef}
              {...provided.droppableProps}
            >
              {channels?.map?.((channel, index) => (
                <Channel
                  channels={channels}
                  templateIdentifier={templateIdentifier}
                  key={channel?.id}
                  channel={channel}
                  index={index}
                />
              ))}

              {provided.placeholder}
            </ChannelList>
          )}
        </Droppable>
      </Container>
    </>
  );
};
