import { Channels, GetChannels } from 'api/channels/get-channels/types';
import { GetTemplateContent } from 'api/templates/get-template/types';
import {
  EditWorkflowJsonMutate,
  SaveWorkflowStateSpec,
} from 'api/workflows/types';
import { produce, setAutoFreeze } from 'immer';
import { NavigateFunction } from 'react-router-dom';
import { Edge, Node, ReactFlowInstance } from 'reactflow';
import useWorkflowStore from 'store/workflowStore';
import * as yup from 'yup';
import { TEMPLATE_EDITOR_TYPES } from '../templateEditor/variables/constants';
import { layoutNodes } from './layout';
import {
  Branch,
  EdgeData,
  HandleErrorsProps,
  NodeData,
  SelecteNodedErrors,
  Step,
  WorkflowDraggableNodeTypes,
  WorkflowIdKeys,
  WorkflowJson,
  WorkflowNodeTypes,
} from './types';
import {
  INDEX_NOT_FOUND,
  WORKFLOW_DEFAULT_ID_KEY_MAP,
  WORKFLOW_DEFAULT_NODES_IDS,
  WORKFLOW_DEFAULT_NODES_JSON,
  WORKFLOW_PLACEHOLDER_POSIITON,
} from './variables';
import { checkDiff } from 'utils/check-diff';
import { DEFAULT_TEMPLATE_STATE } from 'templates/utils';
import { getHashidsInstance } from 'utils/use-hash-id';

/**
 * TODO: refactor when time
 *
 * Our layout function modifies our node positions
 * but immer makes modified values read only.
 * https://immerjs.github.io/immer/freezing
 *
 */
setAutoFreeze(false);

export const createNode = ({
  id,
  type,
  data,
}: {
  id: string;
  type: WorkflowNodeTypes;
  data?: NodeData;
}) => {
  return {
    id: id,
    type: type,
    data,
    position: WORKFLOW_PLACEHOLDER_POSIITON,
  };
};
export const createEdge = ({
  source,
  target,
  sourceHandle = 'bottom',
  type = 'workflow',
  data,
}: {
  source: string;
  target: string;
  sourceHandle?: string;
  type?: 'workflow' | 'placeholder';
  data?: EdgeData;
}) => {
  return {
    id: `e${source}-${target}`,
    source: source,
    target: target,
    sourceHandle,
    type,
    ...(data && { data }),
  };
};

export const handleLayoutNodes = (nodes: Node[], edges: Edge[]) => {
  const { showAllNodeRefs } = useWorkflowStore.getState();
  /**
   * since showing unique node refs, node height increaes
   * and + plus icon will not be visible,
   * so we need to increase nodeYPosition
   */
  return layoutNodes(nodes, edges, showAllNodeRefs ? 1.3 : 1);
};

export const getChannelKeyFromId = ({ id }: { id: string }) => {
  const channelKeyFromId = id?.split?.('_')[0];
  return channelKeyFromId;
};

export const createNewConnections = ({
  source,
  target,
  draggedType,
  reOrderedNode,
  selectCreateNode = true,
  workflowJson,
  editWorkflowJson,
  workflowId,
  reactFlowInstance,
}: {
  source: string;
  target: string;
  draggedType: WorkflowDraggableNodeTypes;
  reOrderedNode: Node<NodeData>;
  selectCreateNode?: boolean;
  editWorkflowJson: EditWorkflowJsonMutate;
  workflowJson?: WorkflowJson;
  workflowId: number;
  reactFlowInstance?: ReactFlowInstance;
}) => {
  const {
    setSelectedNode,
    setEdges,
    setNodes,
    edges,
    nodes,
    setWorkflowJson,
    setIdKeyMap,
    layoutMode,
  } = useWorkflowStore.getState();

  if (reOrderedNode) {
    // reorder node

    const updatedEdges = getReorderedEdges({
      source,
      target,
      reOrderedNode,
      edges,
    });

    const { layoutedNodes, layoutedEdges } = handleLayoutNodes(
      nodes,
      updatedEdges,
    );
    setNodes([...layoutedNodes]);
    setEdges([...layoutedEdges]);
  } else {
    // add node

    const { newNode, newEdge, updatedJsonSteps } = getNewConnection({
      source,
      target,
      draggedType,
    });

    const destoryedEdges = edges.filter(edge => {
      const edgeToBeDestroyed =
        edge.source === source && edge.target === target;
      return !edgeToBeDestroyed;
    });

    const edgeIndex = destoryedEdges.findIndex(edge => edge.source === source);

    const updatedEdges = destoryedEdges.toSpliced(edgeIndex, 0, ...newEdge);

    const updatedNodes = nodes.concat(newNode);
    const { layoutedEdges, layoutedNodes } = handleLayoutNodes(
      updatedNodes,
      updatedEdges,
    );

    // select dragged node, which is newly created
    if (selectCreateNode) {
      const selectedNode = layoutedNodes.find(
        node => newNode?.[0]?.id === node.id,
      );

      setSelectedNode(selectedNode);
    }

    // save the json
    const updatedJson = {
      ...workflowJson,
      steps: updatedJsonSteps,
    };
    setWorkflowJson(updatedJson);

    // save updated id Key map
    const updatedKeyMapId = getKeyMapId({ draggedType });
    setIdKeyMap(updatedKeyMapId);

    // handle the diff check
    handleJsonDiffCheck({
      workflowJson: updatedJson,
    });

    // save nodes and edges
    setNodes([...layoutedNodes]);
    setEdges([...layoutedEdges]);

    if (layoutMode === 'automatic') {
      handleReactFlowFitView({
        reactFlowInstance,
      });
    }

    editWorkflowJson.mutateAsync({
      workflowId,
      jsonSpec: updatedJson,
    });
  }
};

export const handleReactFlowFitView = ({
  reactFlowInstance,
  fitDuration = 800,
  timeoutDuration = 600,
}: {
  reactFlowInstance?: ReactFlowInstance;
  fitDuration?: number;
  timeoutDuration?: number;
}) => {
  setTimeout(() => {
    reactFlowInstance?.fitView({
      maxZoom: 1.5,
      duration: fitDuration,
    });
  }, timeoutDuration);
};

const getKeyMapId = ({
  draggedType,
}: {
  draggedType: WorkflowDraggableNodeTypes;
}) => {
  const { idKeyMap, draggedChannel } = useWorkflowStore.getState();

  const key: WorkflowIdKeys =
    draggedType === 'channel' ? draggedChannel?.channel : draggedType;

  return produce(idKeyMap, draft => {
    if (draggedType === 'branch') {
      draft['branch'] = draft['branch'] + 1;
      draft['subBranch'] = draft['subBranch'] + 2;
    } else {
      draft[key] = draft[key] + 1;
    }
  });
};

export const returnGreaterValue = (a: number, b: number) => {
  return a > b ? a : b;
};

const getIdKeyFromRef = (ref: string) => {
  return Number(ref.split('_')[1]);
};

export const getKeyMapIdFromWorkflowJson = ({
  steps,
}: {
  steps: WorkflowJson['steps'];
}) => {
  return produce(WORKFLOW_DEFAULT_ID_KEY_MAP, draft => {
    if (steps?.length === 0) return draft;

    steps?.forEach(step => {
      const type = step.type;
      const channelKey = step.channelKey;
      const stepIdKey = getIdKeyFromRef(step.ref);
      const key: WorkflowIdKeys = type === 'channel' ? channelKey : type;
      const idKey = returnGreaterValue(draft[key], stepIdKey);

      if (type === 'branch') {
        draft[key] = idKey;

        const allSubBranches = step.branches.flatMap(branch => {
          return branch;
        });

        allSubBranches.forEach(subBranch => {
          const subBranchIdKey = getIdKeyFromRef(subBranch.ref);
          const idKey = returnGreaterValue(draft['subBranch'], subBranchIdKey);
          draft['subBranch'] = idKey;

          const branchSteps = subBranch.steps;
          const branchStepKeyMapId = getKeyMapIdFromWorkflowJson({
            steps: branchSteps,
          });

          Object.keys(draft).forEach(keyMap => {
            const currentKey = keyMap as WorkflowIdKeys;
            draft[currentKey] = returnGreaterValue(
              draft[currentKey],
              branchStepKeyMapId[currentKey],
            );
          });
        });
      } else {
        draft[key] = idKey;
      }
    });
  });
};

/**
 * TODO: update type so that leftNodeId and rightNodeId
 * is only passed in type of branch
 */
const getJsonFromType = ({
  draggedType,
  workflowJson,
  newNodeId,
  source,
  leftNodeId,
  rightNodeId,
}: {
  draggedType: WorkflowDraggableNodeTypes;
  workflowJson: WorkflowJson;
  newNodeId: string;
  source: string;
  leftNodeId?: string;
  rightNodeId?: string;
}) => {
  const jsonFromType = createJsonFromType({
    type: draggedType,
    newNodeId,
    leftNodeId,
    rightNodeId,
  });

  const getUpdateJson = (
    state: WorkflowJson['steps'],
    isLastElement = true,
  ) => {
    return produce(state, draft => {
      // if empty source, then basically the branch
      if (source.includes('empty')) {
        source = source.replace('empty', 'branch');
      }

      // adding to source start node
      if (source === WORKFLOW_DEFAULT_NODES_IDS.start) {
        draft.splice(0, 0, jsonFromType);
        return;
      }

      const sourceIndex = draft.findIndex(json => json.ref === source);
      const hasStepSourceIndex = sourceIndex === INDEX_NOT_FOUND ? false : true;

      /**
       * could not find sourceId from the first bfs search
       * now we have to look for each branch id and
       * the children of these branches
       */
      if (!hasStepSourceIndex) {
        const allBranchJson = draft.filter(json => json.type === 'branch');

        /**
         * EXIT CONDITION FOR RECURSION
         */
        if (allBranchJson.length === 0 && isLastElement) {
          draft.splice(0, 0, jsonFromType);
          return;
        }

        const allSubBranches = allBranchJson.flatMap(branch => {
          return branch.branches?.map(subBranch => subBranch);
        });

        const selectedSubBranch = allSubBranches.find(
          subBranch => subBranch.ref === source,
        );
        /**
         * Since subBranch, we can directly push as the first child
         */
        if (selectedSubBranch) {
          selectedSubBranch?.steps.push(jsonFromType);
          return;
        } else {
          /**
           * We have checked for all branch sources, since we have not found any
           * we need to filter through all the branch steps
           */

          allBranchJson.forEach((branchJson, branchJsonIndex) => {
            branchJson.branches.forEach((json, index) => {
              const steps = json.steps;

              const isLastElement =
                index === branchJson.branches.length && steps.length === 0;

              const updatedStepJson = getUpdateJson(steps, isLastElement);

              allBranchJson[branchJsonIndex].branches[index].steps =
                updatedStepJson;
            });
          });
        }
      } else {
        // insert after the source
        const insertIndex = sourceIndex + 1;
        draft.splice(insertIndex, 0, jsonFromType);
      }
    });
  };

  const updatedJson = getUpdateJson(workflowJson['steps']);
  return updatedJson;
};

const createConfigFromType = ({
  type,
  leftNodeId,
  rightNodeId,
}: {
  type: WorkflowNodeTypes;
  leftNodeId?: string;
  rightNodeId?: string;
}) => {
  const { draggedChannel } = useWorkflowStore.getState();

  if (type === 'triggerWorkflow') {
    return {
      config: {
        workflowIdentifier: '',
      },
      triggerCondition: '',
    };
  }

  if (type === 'waitForInput') {
    return {
      config: null as null,
      channelKey: null as null,
      triggerCondition: '',
    };
  }

  if (type === 'delay') {
    return {
      config: {
        delayFor: '5000',
      },
      triggerCondition: '',
    };
  }

  if (type === 'batch') {
    return {
      config: {
        batchOrder: 'asc',
        batchWindow: 5000,
        batchWindowType: 'fixed',
      },
      triggerCondition: '',
    };
  }

  if (type === 'updatePreference') {
    return {
      config: {
        channel: '',
        preferenceExpression: '',
      },
      triggerCondition: '',
    };
  }

  if (type === 'branch') {
    return {
      config: null as null,
      triggerCondition: null as null,
      branches: [
        {
          ref: leftNodeId,
          triggerCondition: '',
          steps: [] as any,
          name: `Branch 1`,
        },
        {
          ref: rightNodeId,
          triggerCondition: null as null,
          steps: [] as any,
          name: `Default`,
        },
      ],
    };
  }

  if (type === 'channel') {
    return {
      template: null as null,
      channelKey: draggedChannel?.channel,
      triggerCondition: '',
    };
  }

  if (type === 'fetch') {
    return {
      config: {
        url: '',
        type: 'get',
        params: [] as any,
        headers: [] as any,
      },
      triggerCondition: '',
    };
  }

  if (type === 'defineVariable') {
    return {
      config: {
        name: '',
        value: '',
      },
      triggerCondition: '',
    };
  }

  if (type === 'editVariable') {
    return {
      config: {
        name: '',
        value: '',
      },
      triggerCondition: '',
    };
  }
};

const createJsonFromType = ({
  type,
  newNodeId,
  rightNodeId,
  leftNodeId,
}: {
  type: WorkflowDraggableNodeTypes;
  newNodeId: string;
  leftNodeId?: string;
  rightNodeId?: string;
}) => {
  const configData = createConfigFromType({ type, leftNodeId, rightNodeId });

  return {
    type,
    ref: newNodeId,
    ...configData,
  };
};

const getReorderedEdges = ({
  source,
  target,
  edges,
  reOrderedNode,
}: {
  source: string;
  target: string;
  edges: Edge[];
  reOrderedNode: Node<NodeData>;
}) => {
  const reOrderedNodeId = reOrderedNode.id;

  const reOrderedNodeSource = edges.find(
    edge => edge.target === reOrderedNodeId,
  ).source;

  const reOrderedNodeTarget = edges.find(
    edge => edge.source === reOrderedNodeId,
  ).target;

  const edgesRemoved = edges.filter(edge => {
    if (edge.source === source && edge.target === target) return false;

    if (edge.source === reOrderedNodeId && edge.target === reOrderedNodeTarget)
      return false;

    if (edge.source === reOrderedNodeSource && edge.target === reOrderedNodeId)
      return false;

    return true;
  });

  const newEdgesToAdd = [
    createEdge({
      source,
      target: reOrderedNodeId,
    }),
    createEdge({
      source: reOrderedNodeId,
      target: target,
    }),
    createEdge({
      source: reOrderedNodeSource,
      target: reOrderedNodeTarget,
    }),
  ];

  return [...edgesRemoved, ...newEdgesToAdd];
};

const getNewConnection = ({
  source,
  target,
  draggedType,
}: {
  source: string;
  target: string;
  draggedType: WorkflowDraggableNodeTypes;
}) => {
  if (draggedType === 'branch') {
    return getBranchNodeConnections({
      source,
      target,
      draggedType,
    });
  } else {
    return getSingleNodeConnections({
      source,
      target,
      draggedType,
    });
  }
};

const getBranchNodeConnections = ({
  source,
  target,
  draggedType,
}: {
  source: string;
  target: string;
  draggedType: WorkflowDraggableNodeTypes;
}) => {
  const { workflowJson, idKeyMap } = useWorkflowStore.getState();
  const newNodeId = `branch_${idKeyMap['branch'] + 1}`;
  const leftNodeId = `subBranch_${idKeyMap['subBranch'] + 1}`;
  const rightNodeId = `subBranch_${idKeyMap['subBranch'] + 2}`;
  const emptyNodeId = `empty_${idKeyMap['branch'] + 1}`;

  const newNode = [
    createNode({
      id: newNodeId,
      type: 'branch',
      data: {
        branchSourceId: source,
        branchTargetId: target,
        branchEndNodeId: target,
        childrenNodeIds: [
          { id: leftNodeId, edgeType: 'placeholder' },
          { id: rightNodeId, edgeType: 'placeholder' },
        ],
      },
    }),
    createNode({
      id: leftNodeId,
      type: 'label',
    }),
    createNode({
      id: rightNodeId,
      type: 'label',
    }),
    createNode({
      id: emptyNodeId,
      type: 'empty',
    }),
  ];

  const newEdge = [
    createEdge({
      source: source,
      target: newNodeId,
      data: {
        parentId: newNodeId,
      },
    }),
    createEdge({
      source: newNodeId,
      target: leftNodeId,
      type: 'placeholder',
      data: {
        parentId: newNodeId,
      },
    }),
    createEdge({
      source: newNodeId,
      target: rightNodeId,
      type: 'placeholder',
      data: {
        parentId: newNodeId,
      },
    }),
    createEdge({
      source: leftNodeId,
      target: emptyNodeId,
      data: {
        parentId: newNodeId,
      },
    }),
    createEdge({
      source: rightNodeId,
      target: emptyNodeId,
      data: {
        parentId: newNodeId,
      },
    }),
    createEdge({
      source: emptyNodeId,
      target: target,
      data: {
        parentId: newNodeId,
      },
    }),
  ];

  const updatedJsonSteps = getJsonFromType({
    draggedType,
    workflowJson,
    newNodeId,
    source,
    leftNodeId,
    rightNodeId,
  });

  return { newNode, newEdge, updatedJsonSteps };
};

const getSingleNodeConnections = ({
  source,
  target,
  draggedType,
}: {
  source: string;
  target: string;
  draggedType: WorkflowDraggableNodeTypes;
}) => {
  const { workflowJson, draggedChannel, idKeyMap } =
    useWorkflowStore.getState();
  const channel = draggedChannel?.channel;

  const key: WorkflowIdKeys = draggedType === 'channel' ? channel : draggedType;

  const newNodeId = `${key}_${idKeyMap[key] + 1}`;

  const config = createConfigFromType({ type: draggedType });

  const newNode = [
    createNode({
      id: newNodeId,
      type: draggedType,
      data: {
        channelKey: draggedChannel?.channel,
        ...config,
      },
    }),
  ];

  const newEdge = [
    createEdge({
      source: source,
      target: newNodeId,
    }),
    createEdge({
      source: newNodeId,
      target: target,
    }),
  ];

  const updatedJsonSteps = getJsonFromType({
    draggedType,
    workflowJson,
    newNodeId: newNodeId,
    source,
  });

  return { newNode, newEdge, updatedJsonSteps };
};

export const handleAddNewNodestoBranch = ({
  index,
  selectedNodeId,
  editWorkflowJson,
  workflowId,
  reactFlowInstance,
}: {
  index: number;
  selectedNodeId: string;
  editWorkflowJson: EditWorkflowJsonMutate;
  workflowId: number;
  reactFlowInstance?: ReactFlowInstance;
}) => {
  const {
    selectedNode,
    setSelectedNode,
    setEdges,
    setNodes,
    nodes,
    edges,
    workflowJson,
    setWorkflowJson,
    idKeyMap,
    setIdKeyMap,
    layoutMode,
  } = useWorkflowStore.getState();

  const newSubBranchId = `subBranch_${idKeyMap['subBranch'] + 1}`;
  const emptyNodeId = `${selectedNode.id.replace('branch', 'empty')}`;

  const newNode = [
    createNode({
      id: newSubBranchId,
      type: 'label',
    }),
  ];

  const newEdge = [
    createEdge({
      source: selectedNode.id,
      type: 'placeholder',
      target: newSubBranchId,
      data: {
        parentId: selectedNode.id,
      },
    }),
    createEdge({
      source: newSubBranchId,
      target: emptyNodeId,
      data: {
        parentId: selectedNode.id,
      },
    }),
  ];

  const changedNodes = nodes.map(node => {
    if (node.id === selectedNode.id) {
      return {
        ...node,
        data: {
          ...node.data,
          childrenNodeIds: [
            ...node.data?.childrenNodeIds,
            { id: newSubBranchId, edgeType: 'placeholder' },
          ],
        },
      };
    }

    return node;
  });

  const selectedJson = findSelectedNodeFromJson({
    selectedNodeId,
    steps: workflowJson.steps,
  });
  const selectedSubBranch = selectedJson.branches[index - 1];
  const edgeIndex = edges.findIndex(
    edge => edge.target === selectedSubBranch.ref,
  );
  const updatedNodes = changedNodes.concat(newNode);
  const updatedEdges = edges.toSpliced(edgeIndex + 1, 0, ...newEdge);
  const { layoutedNodes, layoutedEdges } = handleLayoutNodes(
    updatedNodes,
    updatedEdges,
  );

  // json data
  const updatedWorkflowJson = produce(workflowJson, draft => {
    const selectedStep = draft.steps?.find(
      json => json.ref === selectedNode.id,
    );

    selectedStep.branches?.splice(index, 0, {
      ref: newSubBranchId,
      triggerCondition: '',
      name: `Branch ${index + 1}`,
      steps: [],
    });

    // Error is directly comming from the backend now

    /**
     * need to customly add error, for manual branch add
     */
    // const selectedError = draft.errors?.find(
    //   error => error.stepRef === selectedNodeId,
    // );

    // if (selectedError) {
    //   selectedError?.errors?.push({
    //     type: 'required',
    //     message: 'Non default branch must have at least one condition',
    //     ref: newSubBranchId,
    //   });
    // } else {
    //   draft.errors.push({
    //     stepRef: selectedNodeId,
    //     errors: [
    //       {
    //         type: 'required',
    //         message: 'Non default branch must have at least one condition',
    //         ref: newSubBranchId,
    //       },
    //     ],
    //   });
    // }

    return;
  });

  const updatedKeyMapId: Record<WorkflowIdKeys, number> = {
    ...idKeyMap,
    subBranch: idKeyMap['subBranch'] + 1,
  };

  // need to explicitly set selected node
  setSelectedNode({
    ...selectedNode,
    data: {
      ...selectedNode.data,
      childrenNodeIds: [
        ...selectedNode.data?.childrenNodeIds,
        { id: newSubBranchId, edgeType: 'placeholder' },
      ],
    },
  });

  setWorkflowJson(updatedWorkflowJson);
  setIdKeyMap(updatedKeyMapId);
  setNodes([...layoutedNodes]);
  setEdges([...layoutedEdges]);

  if (layoutMode === 'automatic') {
    handleReactFlowFitView({
      reactFlowInstance,
    });
  }

  editWorkflowJson.mutate({
    workflowId,
    jsonSpec: updatedWorkflowJson,
  });
};

// load from json
export const createNodesAndEdgesFromJsonData = ({
  json,
}: {
  json: WorkflowJson;
}) => {
  const allNodesJson = [
    WORKFLOW_DEFAULT_NODES_JSON[0],
    ...(json.steps ?? []),
    WORKFLOW_DEFAULT_NODES_JSON[1],
  ];

  const { setEdges, setNodes } = useWorkflowStore.getState();
  const nodes = createNodesFromJsonData({
    json: allNodesJson as WorkflowJson['steps'],
  });
  const edges = createEdgesfromNodes({ nodes });
  const { layoutedNodes, layoutedEdges } = handleLayoutNodes(nodes, edges);
  setNodes([...layoutedNodes]);
  setEdges([...layoutedEdges]);
};

const createNodesFromJsonData = ({
  json,
  branchTargetId,
}: {
  json: WorkflowJson['steps'];
  branchTargetId?: string;
}): Node[] => {
  const nodesFromJson = json.reduce((acc, curr, index) => {
    const { ref, type, branches, ...data } = curr;
    const nextNode = json?.[index + 1];
    const nextNodeId = nextNode?.ref;

    const baseNode = createNode({
      id: curr.ref,
      type: curr.type,
      data: {
        childrenNodeIds: [
          {
            edgeType: 'workflow',
            id: nextNodeId
              ? nextNodeId
              : branchTargetId
                ? branchTargetId
                : null,
          },
        ],
        ...data,
      },
    });

    // branch item
    if (type === 'branch') {
      const emptyNodeId = curr.ref.replace('branch', 'empty');

      const { branchNodes, stepNodes } = branches.reduce(
        (acc, curr, index: number) => {
          const branchSteps = curr.steps;
          const hasBranchSteps = branchSteps.length !== 0;
          const firstBranchStepId = branchSteps?.[0]?.ref;

          const newBranchNodes = createNode({
            id: curr.ref,
            type: 'label',
            data: {
              edgeTypeForBaseNode: 'placeholder',
              childrenNodeIds: [
                {
                  id: hasBranchSteps ? firstBranchStepId : emptyNodeId,
                  edgeType: 'workflow',
                },
              ],
              ...curr,
            },
          });

          const stepNodes = createNodesFromJsonData({
            json: branchSteps,
            branchTargetId: emptyNodeId,
          });

          return {
            branchNodes: [...(acc.branchNodes ?? []), newBranchNodes],
            stepNodes: [...(acc.stepNodes ?? []), ...stepNodes],
          };
        },
        { branchNodes: [], stepNodes: [] },
      );

      const branchBaseNode = {
        ...baseNode,
        data: {
          ...baseNode.data,
          childrenNodeIds: branchNodes?.map(node => ({
            id: node.id,
            edgeType: node.data?.edgeTypeForBaseNode,
          })),
        },
      };

      const emptyNode = createNode({
        id: emptyNodeId,
        type: 'empty',
        data: {
          childrenNodeIds: [
            {
              id: nextNodeId
                ? nextNodeId
                : branchTargetId
                  ? branchTargetId
                  : null,
              edgeType: 'workflow',
            },
          ],
        },
      });

      return [
        ...acc,
        emptyNode,
        branchBaseNode,
        ...(branchNodes ?? []),
        ...(stepNodes ?? []),
      ];
    }

    // single item
    return [...acc, baseNode];
  }, []);

  return nodesFromJson;
};

const createEdgesfromNodes = ({ nodes }: { nodes: Node<NodeData>[] }) => {
  return nodes.reduce((acc, curr) => {
    const currentNode = curr;
    // filter out null values
    const childrenNodeIds = currentNode?.data?.childrenNodeIds.filter(id => id);

    if (childrenNodeIds?.length > 0) {
      const edges = childrenNodeIds?.reduce?.((acc, childNodeId) => {
        if (!childNodeId.id) return acc;

        return [
          ...acc,
          createEdge({
            source: currentNode.id,
            target: childNodeId.id,
            type: childNodeId.edgeType,
          }),
        ];
      }, []);

      return [...acc, ...edges];
    } else {
      return acc;
    }
  }, []);
};

export const convertTimeToMilliseconds = ({
  days,
  hours,
  mins,
  secs,
}: {
  days: string | number;
  hours: string | number;
  mins: string | number;
  secs: string | number;
}) => {
  const daysInSeconds = Number(days) * 60 * 60 * 24;
  const hoursInSeconds = Number(hours) * 60 * 60;
  const minsInSeconds = Number(mins) * 60;

  return (daysInSeconds + hoursInSeconds + minsInSeconds + Number(secs)) * 1000;
};

export function convertMillisecondsToTime(milliseconds: string | number) {
  const ms = Number(milliseconds);
  const days = Math.floor(ms / (24 * 60 * 60 * 1000));
  const daysms = ms % (24 * 60 * 60 * 1000);
  const hours = Math.floor(daysms / (60 * 60 * 1000));
  const hoursms = ms % (60 * 60 * 1000);
  const mins = Math.floor(hoursms / (60 * 1000));
  const minutesms = ms % (60 * 1000);
  const secs = Math.floor(minutesms / 1000);

  return {
    days,
    hours,
    mins,
    secs,
  };
}

export function findSelectedNodeFromJson({
  steps,
  selectedNodeId,
}: {
  steps: WorkflowJson['steps'];
  selectedNodeId: string;
}) {
  const selectedStepNode = steps?.find(step => step.ref === selectedNodeId);

  /**
   * if not directly in steps, we need to
   * search the sub branches and branch child nodes
   */
  if (!selectedStepNode) {
    const allBranchJson = steps?.filter(json => json.type === 'branch');
    const allSubBranches = allBranchJson?.flatMap(branch => {
      return branch?.branches?.map(subBranch => ({
        ...subBranch,
        branchParentRef: branch?.ref,
      }));
    });
    const selectedSubBranch = allSubBranches?.find(
      subBranch => subBranch.ref === selectedNodeId,
    );

    if (selectedSubBranch) {
      /**
       * TODO: refactor to change return type based on prop
       * currently typecasted as Step when this is a Branch
       * did this because we are only using this function for Step types
       */
      return selectedSubBranch as unknown as Step;
    } else {
      /**
       * since not branches data, we need to search for
       * every step node inside branch
       */
      let selectedNode;
      allSubBranches?.forEach(json => {
        const steps = [...(json.steps ?? [])];
        const selected = findSelectedNodeFromJson({
          steps: steps,
          selectedNodeId,
        });

        if (selected) {
          selectedNode = selected;
        }
      });

      return selectedNode;
    }
  } else {
    return selectedStepNode;
  }
}

export function findStepsBeforeSelectedNodeFromJson({
  steps,
  selectedNodeId,
  selectOnlyChannels = false,
}: {
  steps: WorkflowJson['steps'];
  selectedNodeId: string;
  selectOnlyChannels?: boolean;
}) {
  const stepsBeforeSelectedNodeFromJson = produce<Step[]>([], draft => {
    steps.some(step => {
      const breakLoop = step.ref === selectedNodeId;

      if (breakLoop) {
        return true;
      }

      if (selectOnlyChannels && step.type === 'channel') {
        draft.push(step);
      }

      if (!selectOnlyChannels) {
        draft.push(step);
      }

      if (step.type === 'branch') {
        const allSubBranches = step?.branches?.flatMap(branch => branch);
        allSubBranches.forEach(subBranch => {
          const subBranchStep = subBranch.steps;
          const subBranchChannelsBeforeSelectedNodeFromJson =
            findStepsBeforeSelectedNodeFromJson({
              steps: subBranchStep,
              selectedNodeId,
              selectOnlyChannels,
            });

          draft.push(...subBranchChannelsBeforeSelectedNodeFromJson);
        });
      }

      return false;
    });
  });

  return stepsBeforeSelectedNodeFromJson;
}

function isWorkflowBranch(selected: Step | Branch): selected is Branch {
  return (selected as Branch).steps !== undefined;
}

// TOOD: refactor to allow aupdate any config
export const getUpdatedWorkflowUpdateJson = ({
  templateIdentifier,
  template,
  updateConfigInstead,
}: {
  templateIdentifier: string;
  template?: GetTemplateContent;
  updateConfigInstead?: boolean;
}) => {
  const { workflowJson } = useWorkflowStore.getState();

  const updatedWorkflowJson = produce(workflowJson, draft => {
    const selected = findSelectedNodeFromJson({
      steps: draft.steps,
      selectedNodeId: templateIdentifier,
    });

    if (!isWorkflowBranch(selected)) {
      if (updateConfigInstead) {
        selected.config = template as any;
      } else {
        selected.template = template;
      }
    }
  });

  return updatedWorkflowJson;
};

export const setUpdatedWorkflowJson = async ({
  templateId,
  templateIdentifier,
  template,
  editWorkflowJson,
  updateConfigInstead,
}: {
  templateId: string;
  templateIdentifier: string;
  template?: GetTemplateContent;
  editWorkflowJson: EditWorkflowJsonMutate;
  updateConfigInstead?: boolean;
}) => {
  const { setWorkflowJson } = useWorkflowStore.getState();

  const updatedWorkflowJson = getUpdatedWorkflowUpdateJson({
    templateIdentifier,
    template,
    updateConfigInstead,
  });

  setWorkflowJson(updatedWorkflowJson);

  await editWorkflowJson.mutateAsync({
    workflowId: Number(templateId),
    jsonSpec: updatedWorkflowJson,
  });
};

const findAllBranchChildIds = ({
  steps,
  selected,
}: {
  steps: WorkflowJson['steps'];
  selected: WorkflowJson['steps'][0];
}): string[] => {
  if (selected && selected?.type === 'branch') {
    const branchRefs = selected.branches?.map(branch => branch.ref);

    const subStepRefs = selected.branches?.reduce((acc, branch) => {
      const subBranchSteps = branch.steps;

      const subBranchChildRefs = subBranchSteps.reduce((acc, curr) => {
        const childRefs = findAllBranchChildIds({
          steps: subBranchSteps,
          selected: curr,
        });

        return [...acc, ...childRefs];
      }, []);

      return [...acc, ...subBranchChildRefs];
    }, []);

    return [selected.ref, ...branchRefs, ...subStepRefs];
  } else {
    const stepNodes = steps.map(step => step.ref);
    return stepNodes;
  }
};

const findIdsToRemoveBasedOnSelectedNode = ({
  steps,
  selectedNodeId,
}: {
  steps: WorkflowJson['steps'];
  selectedNodeId: string;
}) => {
  const selected = findSelectedNodeFromJson({
    steps,
    selectedNodeId,
  });

  if (selected.type === 'branch') {
    return findAllBranchChildIds({
      steps,
      selected,
    });
  } else {
    return selectedNodeId;
  }
};

// delete nodes
export const handleDeleteSelectedNode = async ({
  workflowJson,
  selectedNodeId,
  editWorkflowJson,
  workflowId,
  reactFlowInstance,
  deSelectNode = true,
  saveWorkflowStateSpec,
}: {
  workflowJson: WorkflowJson;
  selectedNodeId: string;
  editWorkflowJson: EditWorkflowJsonMutate;
  workflowId: number;
  reactFlowInstance?: ReactFlowInstance;
  deSelectNode?: boolean;
  saveWorkflowStateSpec?: SaveWorkflowStateSpec;
}) => {
  const { setSelectedNode, setWorkflowJson, layoutMode } =
    useWorkflowStore.getState();

  const selectedJson = findSelectedNodeFromJson({
    steps: workflowJson.steps,
    selectedNodeId,
  });

  const allIdsToRemoveFromErrors = findIdsToRemoveBasedOnSelectedNode({
    steps: workflowJson.steps,
    selectedNodeId,
  });

  const updatedBaseErrors = workflowJson?.errors?.filter(error => {
    return !allIdsToRemoveFromErrors.includes(error.stepRef);
  });

  const updatedChildErrors = produce(updatedBaseErrors, draft => {
    draft?.forEach((error, index) => {
      const updatedChildErrors = error?.errors?.filter(
        error => !allIdsToRemoveFromErrors.includes(error.stepRef),
      );

      draft[index].errors = updatedChildErrors;
      return;
    });
  });

  const updatedErrors = updatedChildErrors?.filter(
    error => error?.errors?.length > 0,
  );

  const updateWorkflowJsonSteps = getWorkflowJsonAfterNodeDelete({
    steps: workflowJson.steps,
    selectedNodeId,
  });

  const updateWorkflowJson = {
    ...workflowJson,
    steps: updateWorkflowJsonSteps,
    errors: updatedErrors,
  };

  if (deSelectNode) {
    setSelectedNode(null);
  }

  handleJsonDiffCheck({
    workflowJson: updateWorkflowJson,
  });
  setWorkflowJson(updateWorkflowJson);
  createNodesAndEdgesFromJsonData({
    json: updateWorkflowJson,
  });

  if (layoutMode === 'automatic') {
    handleReactFlowFitView({
      reactFlowInstance,
    });
  }

  try {
    await editWorkflowJson.mutateAsync({
      workflowId,
      jsonSpec: updateWorkflowJson,
    });

    if (
      selectedJson?.type === 'channel' &&
      selectedJson?.channelKey === 'inApp'
    ) {
      saveWorkflowStateSpec?.mutate({
        workflowId: String(workflowId),
        data: {
          ref: selectedNodeId,
          state: DEFAULT_TEMPLATE_STATE,
          stateSpec: {},
        },
      });
    }
  } catch (e) {
    console.log(e);
  }
};

const getWorkflowJsonAfterNodeDelete = ({
  steps,
  selectedNodeId,
}: {
  steps: WorkflowJson['steps'];
  selectedNodeId: string;
}) => {
  const updateWorkflowJson = produce(steps, draft => {
    const selectedStepNodeIndex = draft.findIndex(
      step => step.ref === selectedNodeId,
    );

    // need to search sub branches
    if (selectedStepNodeIndex === INDEX_NOT_FOUND) {
      const allBranchJson = draft.filter(json => json.type === 'branch');

      const allSubBranches = allBranchJson.flatMap(branch => {
        return branch.branches.map(subBranch => subBranch);
      });

      const selectedSubBranchIndex = allSubBranches.findIndex(
        subBranch => subBranch.ref === selectedNodeId,
      );

      if (selectedSubBranchIndex === INDEX_NOT_FOUND) {
        // need to search children
        allSubBranches.forEach((json, index) => {
          const steps = [...json.steps];

          const updatedStepsWorkflowJson = getWorkflowJsonAfterNodeDelete({
            steps,
            selectedNodeId,
          });

          allSubBranches[index].steps = updatedStepsWorkflowJson;
        });

        return;
      } else {
        // remove sub branch
        const selectedSubBranch = allSubBranches[selectedSubBranchIndex];

        allBranchJson.forEach((branch, index) => {
          const filteredSubBranches = branch.branches.filter(
            branch => branch.ref !== selectedSubBranch.ref,
          );

          allBranchJson[index].branches = filteredSubBranches;
        });

        return;
      }
    } else {
      draft.splice(selectedStepNodeIndex, 1);
      return;
    }
  });

  return updateWorkflowJson;
};

export const handleJsonDiffCheck = ({
  workflowJson,
}: {
  workflowJson: WorkflowJson;
}) => {
  const { setHasJsonChanges, publishedWorkflowJson } =
    useWorkflowStore.getState();
  const { publishedCode, latestCode } = createWorkflowDiffFromJson({
    publishedWorkflowJson,
    workflowJson,
  });

  const hasChange = checkDiff({
    oldValue: publishedCode,
    newValue: latestCode,
  });

  if (hasChange) {
    setHasJsonChanges(true);
  } else {
    setHasJsonChanges(false);
  }
};

export const handleDeselectAndSave = ({
  workflowId,
  editWorkflowJson,
  workflowJson,
}: {
  workflowId: number;
  editWorkflowJson: EditWorkflowJsonMutate;
  workflowJson: WorkflowJson;
}) => {
  const { setSelectedNode, setWorkflowJson } = useWorkflowStore.getState();

  setSelectedNode(null);
  setWorkflowJson(workflowJson);

  editWorkflowJson.mutate({ workflowId, jsonSpec: workflowJson });
};

export const getCurrentJsonWithErrors = ({
  workflowJson,
  error,
}: {
  error: SelecteNodedErrors;
  workflowJson: WorkflowJson;
}) => {
  const stepRef = error?.stepRef;
  const validJsonErrors = workflowJson.errors?.filter(
    error => error?.errors?.length > 0,
  );
  const workflowJsonWithErrors = {
    ...workflowJson,
    errors: [...(validJsonErrors ?? [])],
  };

  const updatedJsonWorkflow = produce(workflowJsonWithErrors, draft => {
    const hasErrors = error?.errors?.length > 0;

    const index = draft?.errors?.findIndex(error => error?.stepRef === stepRef);

    if (index !== INDEX_NOT_FOUND) {
      if (!hasErrors) {
        draft?.errors?.splice(index, 1);
      } else {
        draft.errors[index].errors = error?.errors;
      }
    } else {
      if (!hasErrors) return;

      draft.errors.push({
        stepRef,
        errors: error?.errors,
      });
    }
  });

  return updatedJsonWorkflow;
};

export function isEmptyObj(obj: Object) {
  return Object.keys(obj).length === 0;
}

export const checkTimeExceedsMaxLimit = ({
  days,
  hours,
  mins,
  secs,
}: {
  days: number | string;
  hours: number | string;
  mins: number | string;
  secs: number | string;
}) => {
  // 999 days in milliseconds
  const maxDaysInMilliSeconds = 86313600000;

  const delayFor = convertTimeToMilliseconds({
    days,
    hours,
    mins,
    secs,
  });

  const delayExceedsMaxDays = maxDaysInMilliSeconds < delayFor;

  return delayExceedsMaxDays;
};

export const handleTimeExceedsErrors = ({
  setErrors,
  errors,
  dataValues,
}: HandleErrorsProps & {
  dataValues: {
    days?: string;
    hours?: string;
    mins?: string;
    secs?: string;
  };
}) => {
  const { days, hours, mins, secs } = dataValues;

  const delayExceedsMaxDays = checkTimeExceedsMaxLimit({
    days,
    hours,
    mins,
    secs,
  });

  if (delayExceedsMaxDays || isEmptyObj(errors)) {
    setErrors({ errors });
  }
};

export function mergeSchemas(...schemas: yup.ObjectSchema<any>[]) {
  const [first, ...rest] = schemas;

  const merged = rest.reduce(
    (mergedSchemas, schema) => mergedSchemas.concat(schema),
    first,
  );

  return merged;
}

export const handleNavigateToChannelEditor = async ({
  selectedNodeId,
  navigate,
  workflowId,
  editWorkflowJson,
}: {
  selectedNodeId: string;
  navigate: NavigateFunction;
  workflowId: number;
  editWorkflowJson: EditWorkflowJsonMutate;
}) => {
  const { workflowJson } = useWorkflowStore.getState();
  const hashIds = getHashidsInstance();
  const hashedWorkflowId = hashIds.encode(workflowId);

  const selectedNodeJson = findSelectedNodeFromJson({
    steps: workflowJson.steps,
    selectedNodeId,
  });
  const type = selectedNodeJson.type;
  const channel = selectedNodeJson?.channelKey;
  const channelKey = type === 'fetch' ? 'fetch' : channel;
  const workflowNodeId = selectedNodeJson.ref;

  editWorkflowJson.mutateAsync({
    workflowId: Number(workflowId),
    jsonSpec: workflowJson,
  });

  navigate(
    `/admin/template-editor/${hashedWorkflowId}/${channelKey}/${workflowNodeId}/${TEMPLATE_EDITOR_TYPES.WORKFLOW}`,
  );
};

export const createWorkflowDiffFromJson = ({
  publishedWorkflowJson,
  workflowJson,
}: {
  publishedWorkflowJson: WorkflowJson;
  workflowJson: WorkflowJson;
}) => {
  const publishedCode = JSON.stringify(
    {
      name: publishedWorkflowJson?.name,
      steps: publishedWorkflowJson?.steps,
    },
    undefined,
    2,
  );

  const latestCode = JSON.stringify(
    { name: workflowJson?.name, steps: workflowJson?.steps },
    undefined,
    2,
  );

  return {
    publishedCode,
    latestCode,
  };
};

export const checkJsonHasErrors = () => {
  const { workflowJson } = useWorkflowStore.getState();

  const hasErrors =
    workflowJson?.errors?.filter(error => error?.errors?.length > 0)?.length >
    0;

  return hasErrors;
};

export const filterChannelConfiguredErrors = ({
  workflowJson,
  enabledChannels,
}: {
  workflowJson: WorkflowJson;
  enabledChannels: GetChannels[];
}) => {
  const updatedErrors = workflowJson?.errors?.filter(error => {
    const channelKeyFromId = getChannelKeyFromId({ id: error?.stepRef });
    const enabledChannelIds = enabledChannels?.map(channel => channel?.channel);

    if (enabledChannelIds?.includes(channelKeyFromId as Channels)) {
      return false;
    }

    return true;
  });

  return updatedErrors;
};

export const clipOverflowWords = ({
  word,
  wordCount,
}: {
  word: string;
  wordCount: number;
}) => {
  if (!word) return '';

  if (word.length > wordCount) {
    const slicedWord = word.slice(0, wordCount);
    return `${slicedWord}...`;
  }

  return word;
};

export const isInteger = (num: string) => /^-?[0-9]+$/.test(num + '');
